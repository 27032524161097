import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
// import "./index.css";
import "./css/style.css";
import "./css/main.css";
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";
 
ReactDOM.render(
  <Main/>, 
  document.getElementById("root")
);