import React, { Component } from "react";
 
class Screenwriting extends Component {
  render() {
    return (
        <div id="main">
        <section id="first" className="main special">
            <header className="major">
            <h2>Screenwriting</h2>
            </header>
            <div className="content">
            <p>As a major fan of movies and television, I now consider becoming a Screenwriter one of my major goals. The rules of Screenwriting add a structure that I enjoy, and the collaborative nature of filmmaking guarantees I will never stop learning. </p>
            <h2 style={{marginBottom: '-2em'}}>My Screenplays</h2>
            </div></section>
        <section className="posts">
            <article>
            <header>
                <span className="category">Horror Short (23 pages)</span>
                <h2>Complex</h2>
            </header>
            <p className="image fit"><img className="zoom" src={require('./images/ComplexImage.png')} alt="" /></p>
            <p>"Two men engage with each other in a mysterious dark room. One of them is feeding his urges, while the other has to find a way to escape and prevent further abuse."</p>
            <ul className="actions special">
                <li><a href="https://drive.google.com/file/d/1bwbWtXNyR5cQ1m_hp2tRO5Afhfqcn4dt/view?usp=sharing" target="_blank" className="button">Read</a></li>
            </ul>
            </article>
            <article>
            <header>
                <span className="category">Horror Short (17 pages)</span>
                <h2>Shared Understanding</h2>
            </header>
            <p className="image fit"><img className="zoom" src={require('./images/SharedUnderstanding.png')} alt="" /></p>
            <p>Killer Shorts 2020 - Quarterfinalist</p>
            <p>"A Japanese girl gets adopted by an American family. Studying the language, she begins to learn more and more about her new life."</p>
            <ul className="actions special">
                <li><a href="https://drive.google.com/file/d/15W8zG9YtU_igUXTe0uXyj4R_GJWaJfqO/view?usp=sharing" target="_blank" className="button">Read</a></li>
            </ul>
            </article>
        </section>
    </div>
    );
  }
}

 
export default Screenwriting;