import React, { Component } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import DigitusTouchONEOF from '../images/DigitusTouchONEOF.pdf'

class DigitusPDF extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  goToPrevPage = () =>
  this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
  this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  render() {
    const { pageNumber, numPages } = this.state;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    return (
      <div>
        <Document
          file={DigitusTouchONEOF}
          onLoadSuccess={this.onDocumentLoadSuccess}
          
        >
          <Page pageNumber={pageNumber} scale={1.3} />
        </Document>
        <button onClick={this.goToPrevPage}>Prev</button>
          <button onClick={this.goToNextPage}>Next</button>
      </div>
    );
  }
}

export default DigitusPDF;