import React, { Component } from "react";
import PageSymbol from "../components/PageSymbol";
 
class Header extends Component {
  render() {
    return (
        <div id="header" className="alt">
            <span className="logo">
                <div className="flip-container">
                    <div class="flipper">
                        <div className="front"><img src={require('../images/logo.jpg')} style={{'borderRadius': '50%'}} alt="" /></div>
                        <div className="back"><img src={require('../images/logo2.png')} style={{'borderRadius': '50%'}} alt="" /></div>
                    </div>
                </div>
            </span>
            <div><h1>ONE OF <PageSymbol /></h1>
			<p>by Robbert "Broeder" Troost</p></div>
		<meta charset="utf-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />		
	</div>
    );
  }
}
 
export default Header;