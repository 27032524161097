import React, { Component } from "react";
import { Route, NavLink, BrowserRouter, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Home from "./Home";
import Stuff from "./Stuff";
import Contact from "./Contact";
import Digitus from "./Digitus";
import Esports from "./Esports";
import Screenwriting from "./Screenwriting";
import DND from "./DND";
import Form from "./Form";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./NotFound";


class Main extends Component {
  render() {
    return (
      <BrowserRouter>
      <ScrollToTop />
        <div id="wrapper">
          <Header/>
            <Nav/>
          <div id="main">
            <div className="content" style={{paddingTop: "2em"}}>
            <Switch>
             <Route exact path="/" component={Home}/>
             <Route path="/stuff" component={Stuff}/>
             <Route path="/contact" component={Contact}/>
             <Route path="/digitus" component={Digitus}/>
             <Route path="/esports" component={Esports}/>
             <Route path="/screenwriting" component={Screenwriting}/>
             <Route path="/dnd" component={DND}/>
             <Route path="/form" component={Form}/>
             <Route component={NotFound} />
             </Switch>
            </div>
          </div>
        <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}
 
export default Main;