import React, { Component } from "react";
import {FaBlackTie, FaHeartbeat} from 'react-icons/fa';
 
class Home extends Component {
  render() {

    const iconStyle = {
      border: "solid 1px",
      display: "inline-block",
      borderRadius: "100%",
      borderColor: "LightGrey",
      padding: "15px 15px 10px 15px",
    }
    const iconStyle2 = {
      border: "solid 1px",
      display: "inline-block",
      borderRadius: "100%",
      borderColor: "LightGrey",
    }

    return (
        <div id="main">
        <section id="first" className="main special">
          <header className="major">
            <h2>Welcome</h2>
          </header>
          <div className="content">
            <p>Welcome to ONEOF.XYZ. This website was created with a specific purpose: pushing me to try different things, acting as an archive of that goal as well as a way to share the things I create. </p>
            <ul className="features">
              <li>
                <span className="icon style1" style={iconStyle}><FaBlackTie size={64}/></span>
                <h3>Professional</h3>
                <p>As Writer/Designer, I focus on the fields of Esports and Gaming culture, and the realm of narrative collaboration, designing online campaigns of all kinds.</p>
              </li>
              <li>
                <span className="icon style3" style={iconStyle}><FaHeartbeat size={64}/></span>
                <h3>Personal</h3>
                <p>I greatly enjoy understanding other disciplines, immersing myself to get beyond a surface level understanding.</p>
              </li>
            </ul>
          </div></section>
        <section id="second" className="main special">
          <header className="major">
            <h2>My Background</h2>
          </header>
          <div className="content">
            <p style={{textAlign: 'right'}}><span className="image left"><img className="zoom" src={require('./images/KLI.png')} alt="" /></span>After a year of studying Mathematics in Amsterdam, I swapped majors to focus on the role of information in our world. I finish my BSc in Information Studies at the University of Amsterdam, including an exchange semester focused on Marketing and Business Culture at Yonsei University, South Korea. Finally, I completed a Masters degree in Interactive Media Design in Stockholm, Sweden.</p>
            <p style={{textAlign: 'left'}}><span className="image right"><img className="zoom" src={require('./images/TakeOver.png')} alt="" /></span>Professionally, I have been heavily involved with esports since 2014. Blizzard's games hooked me, leading me to work for GosuGamers.net over the course of 2 years. First as volunteer editor, and then as an employed team manager overseeing the launch of the Overwatch section. 
              Secondly, I have helped my father, who is an expert writer on African Wildlife, by developing several webshops and helping design his books. If you know Dutch or German, take a look at his books <a href="http://www.safarigids.nl" target="_blank" rel="noopener noreferrer" style={{color: '#a05095'}}>here.</a></p>
          </div>
        </section>
      </div>
    );
  }
};                                                                                      

 
export default Home;