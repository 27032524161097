import React, { Component } from "react";
import { Route, NavLink, HashRouter } from "react-router-dom";
import {FaTwitter} from 'react-icons/fa';
 
class Footer extends Component {
  render() {
    return (
        <div>
        <footer id="footer">
            <div>
                <h2>Want to contact me?</h2>
                <p>Are you interested in a collaboration, want to ask me some questions on approaching similar subjects, or just need to email me? I'd love to receive your message!</p>
                <ul className="actions">
                    <li className="first"><NavLink to="/form" className="button theme">Email me</NavLink></li>
                    <li className="second"><a href="https://twitter.com/xbroeder" target="_blank" rel="noopener noreferrer" className="alt button theme"><FaTwitter /></a></li>
                </ul>
            </div>
            <div className="library" style={{backgroundClip: "border-box"}}>
                <h2>All Pages</h2>
                <NavLink to="/" className="button theme" style={{margin: "0.5em"}}>Home</NavLink>
                <NavLink to="/digitus" className="button theme" style={{margin: "0.5em"}}>Digitus</NavLink>
                <NavLink to="/esports" className="button theme" style={{margin: "0.5em"}}>Esports</NavLink>
                <NavLink to="/screenwriting" className="button theme" style={{margin: "0.5em"}}>Screenwriting</NavLink>
                <NavLink to="/dnd" className="button theme" style={{margin: "0.5em"}}>Dungeons & Dragons</NavLink>			
            </div>
            <p className="copyright">
                &copy;ONEOF.XYZ by Robbert "Broeder" Troost | 2019-2020
            </p>
        </footer>		
	</div>
    );
  }
}
 
export default Footer;