import React, { Component } from "react";
 
class Form extends Component {
  render() {
    return (
        <div id="main">
        <section id="content" className="main">
          <h2>Form</h2>
          <form id="contact" action="https://formspree.io/rtroost.93@gmail.com" method="POST" target="_blank">
            <div className="row gtr-uniform">
              <div className="col-6 col-12-xsmall">
                <input type="text" name="name" id="name" placeholder="Name" />
              </div>
              <div className="col-6 col-12-xsmall">
                <input type="email" name="email" id="email" placeholder="Email" />
              </div>
              <div className="col-12">
                <select name="category" id="category">
                  <option value>- Category -</option>
                  <option value={1}>Collaboration</option>
                  <option value={2}>Questions</option>
                  <option value={3}>Other</option>
                </select>
              </div>
              <div className="col-12">
                <textarea name="message" id="message" placeholder="Enter your message" rows={6} defaultValue={""} />
              </div>
              <div className="col-12">
                <ul className="actions">
                  <li><input type="submit" defaultValue="Send Message" className="primary" onclick="confirm()" /></li>
                  <li><input type="reset" defaultValue="Reset" onclick="resetForm()" /></li>
                </ul>
              </div>
              <div id="confirmation" className="col-12">
                <p />
              </div>
            </div>
          </form>
        </section>
      </div>
    );
  }
}

 
export default Form;