import React, { Component } from "react";
import { Route, NavLink, HashRouter, useLocation } from "react-router-dom";
import {FaDiceD20, FaHandPaper, FaPenFancy, FaHeadset} from 'react-icons/fa';
 
export default function PageSymbol() {
    const arg = useLocation();
    if (arg.pathname.endsWith("dnd")) {
      return <FaDiceD20/>;  
    }
    else if (arg.pathname.endsWith("screenwriting")) {
        return <FaPenFancy/>
    }
    else if (arg.pathname.endsWith("digitus")) {
        return <FaHandPaper/>
    }
    else if (arg.pathname.endsWith("esports")) {
        return <FaHeadset/>
    }
    else if (arg.pathname.endsWith("esports")) {
        return <FaHeadset/>
    }
    else {
        return <span style={{opacity: "0%"}}><FaHeadset/></span>
    }
}