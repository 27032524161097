import React, { Component } from "react";

class DND extends Component {
    render() {
      return (
        <div id="main">
        <section id="first" className="main special">
            <header className="major">
            <h2>Dungeons &amp; Dragons</h2>
            </header>
            <div className="content">
            <p>Ever since seeing Acquisitions Incorporated stream 4th edition at PAX, I have been a fan of Dungeons &amp; Dragons. I have had the fortune of playing with people from all over the world, and have gone through many a campaign with my group of close friends. While playing is fun on its own, I prefer taking on the Dungeon Master role. What attracts me to D&amp;D more than anything is constructing <i>realistic</i> scenarios, and then adapting based on the choices of my players. I believe D&amp;D is one of the best (and certainly most enjoyable) ways of learning to stay flexible mentally when things don't happen as you expect.</p>
            <h2 style={{marginBottom: '-2em'}}>My Creations</h2>
            </div></section>

        <section className="posts">
            <article>
            <header>
                <span className="category">One-Shot</span>
                <h2><a href="#">A Most Wondrous Housewarming</a></h2>
            </header>
            <p className="image fit"><img className="zoom" src={require('./images/Housewarming2.png')} alt="" /></p>
            <p>This highly customizable One-Shot will have your players interact with an entertain some of the more interesting villains fantasy has to offer.</p>
            <ul className="actions special">
                <li><a href="https://drive.google.com/file/d/10cBTTNHmEIG4f-vPZJ4UmSFVjbtwr_eg/view?usp=sharing" target="_blank" className="button">Full Story</a></li>
            </ul>
            </article>
            <article>
            <header>
                <span className="category">Dungeon Crawl</span>
                <h2><a href="#">To Be Announced</a></h2>
            </header>
            <p className="image fit"><img className="zoom" src={require('./images/Esher.png')} alt="" /></p>
            <p>My upcoming project focuses on an old-school dungeon crawl, but one where time and space do not behave as one might expect.</p>
            <ul className="actions special">
                <li><a href="#" className="button">Coming</a></li>
            </ul>
            </article>
        </section>
        </div>
      );
    }
  }

  export default DND;