import React, { Component } from "react";
import { Route, NavLink, HashRouter } from "react-router-dom";
import {FaFrownOpen} from 'react-icons/fa';

function NotFound() {
    return (
        <div id="main">
            <section id="first" className="main special">
                <h1>404 - Page not Found</h1>
                <FaFrownOpen size={64} color={"Orchid"}/>
                <p>Apologies. It seems I haven't created whatever you are looking for (yet).</p>

                <p><NavLink to="/">Return to the Homepage</NavLink></p>
            </section>
        </div>
    );
  }                                                                                  

 
export default NotFound;