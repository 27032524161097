import React, { Component } from "react";
import DigitusPDF from "./components/DigitusPDF";
 
class Digitus extends Component {
  render() {
    return (
        <div id="main">
            <section id="first" className="main special">
                <header className="major">
                    <h2>Digitus Touch</h2>
                </header>
            <div className="content">
                <p>During my time at Södertörn University, where I completed my Masters degree in Interactive Media Design, one course stood out. 
                In groups, we were asked to come up with a design for a conference challenge, theme: the Magical Mundane.
                As Interaction Designers, my team combined the following aspects in our prototype highlighted below:</p>
                <ul style={{textAlign: 'left'}}>
                    <li>Conductive Tattoo Ink</li>
                    <li>The Internet of Things</li>
                    <li>Society pushing back against screen interfaces</li>
                </ul>
                <p />
            </div>
            <div id="second" className="main special">
                <h2>Vision</h2>
            <div className="content">
                <iframe title="video" style={{float: 'right', paddingLeft: '1.5em'}} width={560} height={315} src="https://www.youtube.com/embed/j8qL30F_sQE" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                <p style={{textAlign: 'left'}}>Digitus Touch appeals to me as a concept, for a few reasons. The new dimension of self-expression that sub-dermal circuitry might reinforce. The ease of use that this type of interaction provides.</p>
                <p style={{textAlign: 'left'}}>The barrier of entry to circuitry that can (temporarily) be planted under your skin or a skin-like layer is ever decreasing. Beyond the potential for commercial spread, I am excited to one day implement it in my own life.</p>
            </div>
                <a href="https://drive.google.com/file/d/1RVHemucztX9Rk_iduoh08oYGuUvD3yk-/view?usp=sharing" target="_blank" className="button">Full Article</a>
            </div>
            {/* <div style={{width: "100%"}}><DigitusPDF /></div> */}
      </section>
      </div>
    );
  }
}
 
export default Digitus;