import React, { Component } from "react";
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import Slider from "react-slick";


export default class Nav extends Component {
  render() {
    const settings = {
      arrows: true,
      centerMode: true,
      className: "slidernav",
			centerPadding: "10px",
      swipeToSlide: true,
			focusOnSelect: true,
      mobileFirst: true,
      dots: false,
      slidesToShow: 5,
      responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 360,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				}
			]
    }
    return (
      <div> 
        <div id="tagline" style={{opacity: 0.8, marginLeft: "2em"}}>
            At least one of:
        </div>
        <div id="nav">
          <div style={{float: "left", marginLeft: "20px"}}>
            <NavLink to="/">Home</NavLink>
          </div>
          <div className="slidernav center" style={{width: "80%", float: "right", marginRight: "20px"}}>
            <Slider {...settings}>
              <div><NavLink to="/esports">Esports</NavLink></div>
              <div><NavLink to="/dnd">D&D</NavLink></div>
              <div><NavLink to="/screenwriting">Screenwriting</NavLink></div>
              <div><NavLink to="/digitus">Digitus</NavLink></div>
              <div>Card Game (Soon)</div>           
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}