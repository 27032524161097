import React, { Component } from "react";

class Esports extends Component {
    render() {
      return (
  
        <div id="main">
          <section id="first" className="main special">
            <header className="major">
              <h2>Esports</h2>
            </header>
            <div className="content">
              <p>Discovering esports, through Hearthstone, was a big moment in my life. While I played traditional sports, I often found that watching them for any larger extent would bore me. The unique nature of esports broadcasts (charismatic commentators, highly intense and complex gameplay, worthwhile analysis) have attracted me ever since.</p>
              <h2 style={{marginBottom: '-2em'}}>Portfolio</h2>
            </div></section>
          <section className="articles">
            <article>
              <p className="image fit"><img className="zoom" src={require('./images/DreadInterview.png')} alt="" /></p>
              <header>
                <span className="date">Heroes of the Storm</span>
                <h3><a href="#https://www.gosugamers.net/heroesofthestorm/features/38839-dreadnaught-at-katowice-pt-2-my-goals-are-to-influence-the-game-in-a-positive-direction" target="_blank" rel="noopener noreferrer">Dreadnaught at Katowice 2016</a></h3>
              </header>
              <p>Interview</p>
              <ul className="actions special">
                <li><a href="https://www.gosugamers.net/heroesofthestorm/features/38839-dreadnaught-at-katowice-pt-2-my-goals-are-to-influence-the-game-in-a-positive-direction" target="_blank" rel="noopener noreferrer" className="button">Full Story</a></li>
              </ul>
            </article>
            <article>
              <p className="image fit"><img className="zoom" src={require('./images/GosuAwards.png')} alt="" /></p>
              <header>
                <span className="date">Overwatch</span>
                <h3><a href="https://www.gosugamers.net/522-gosuawards-2016-overwatch-winners" target="_blank" rel="noopener noreferrer">GosuAwards 2016</a></h3>
              </header>
              <p>Community Awards</p>
              <ul className="actions special">
                <li><a href="https://www.gosugamers.net/522-gosuawards-2016-overwatch-winners" target="_blank" rel="noopener noreferrer" className="button">Full Story</a></li>
              </ul>
            </article>
            <article>
              <p className="image fit"><img className="zoom" src={require('./images/GrubbyInterview.png')} alt="" /></p>
              <header>
                <span className="date">Heroes of the Storm</span>
                <h3><a href="https://www.gosugamers.net/heroesofthestorm/features/38539-grubby-i-was-very-glad-to-read-that-people-loved-my-commentating" target="_blank" rel="noopener noreferrer">Grubby at the EU Championship 2015</a></h3>
              </header>
              <p>Interview</p>
              <ul className="actions special">
                <li><a href="https://www.gosugamers.net/heroesofthestorm/features/38539-grubby-i-was-very-glad-to-read-that-people-loved-my-commentating" target="_blank" rel="noopener noreferrer" className="button">Full Story</a></li>
              </ul>
            </article>
            <article>
              <p className="image fit"><img className="zoom" src={require('./images/infographic.png')} alt="" /></p>
              <header>
                <span className="date">Overwatch</span>
                <h2><a href="https://www.gosugamers.net/overwatch/features/41204-a-new-meta-emerges-gg18-overview-infographic" target="_blank" rel="noopener noreferrer">Infographic</a></h2>
              </header>
              <p>Infographic Collaboration</p>
              <ul className="actions special">
                <li><a href="https://www.gosugamers.net/overwatch/features/41204-a-new-meta-emerges-gg18-overview-infographic" target="_blank" rel="noopener noreferrer" className="button">Full Story</a></li>
              </ul>
            </article>
            <article>
              <p className="image fit"><img className="zoom" src={require('./images/HeroesStats.png')} alt="" /></p>
              <header>
                <span className="date">Heroes of the Storm</span>
                <h3><a href="https://www.gosugamers.net/heroesofthestorm/features/38530-enter-the-stats-europe-championship" target="_blank" rel="noopener noreferrer">Meta Analysis</a></h3>
              </header>
              <p>Statistical Analysis.</p>
              <ul className="actions special">
                <li><a href="https://www.gosugamers.net/heroesofthestorm/features/38530-enter-the-stats-europe-championship" target="_blank" rel="noopener noreferrer" className="button">Full Story</a></li>
              </ul>
            </article>
            <article>
              <p className="image fit"><img className="zoom" src={require('./images/FnaticSpotlight.png')} alt="" /></p>
              <header>
                <span className="date">Heroes of the Storm</span>
                <h2><a href="https://www.gosugamers.net/heroesofthestorm/features/38481-enter-the-storm-3-team-spotlight-fnatic" target="_blank" rel="noopener noreferrer">Fnatic</a></h2>
              </header>
              <p>Team Spotlight</p>
              <ul className="actions special">
                <li><a href="https://www.gosugamers.net/heroesofthestorm/features/38481-enter-the-storm-3-team-spotlight-fnatic" target="_blank" rel="noopener noreferrer" className="button">Full Story</a></li>
              </ul>
            </article>
          </section>
        </div>
      );
    }
  }

  export default Esports;